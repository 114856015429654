var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"current-position"},[_c('el-breadcrumb',[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_c('span',{staticClass:"icon-home"}),_vm._v("首页")]),_c('el-breadcrumb-item',{attrs:{"to":{ path: '/exam' }}},[_vm._v("考试")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.licence_name))])],1)],1),_c('div',{staticClass:"exercise-box"},[_c('el-tabs',{staticClass:"exercise-list",attrs:{"tab-position":_vm.tabPosition},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.licenceDatas),function(item){return _c('el-tab-pane',{key:item.licence_id,attrs:{"label":item.licence_name,"name":item.licence_id.toString()}},[_c('el-tabs',{attrs:{"type":"border-card"},on:{"tab-click":_vm.handleClick2},model:{value:(_vm.activeName2),callback:function ($$v) {_vm.activeName2=$$v},expression:"activeName2"}},_vm._l((item.children),function(item2){return _c('el-tab-pane',{key:item2.licence_id,attrs:{"label":item2.licence_name,"name":item2.licence_id.toString()}},[(
                item2.subject_data.length != 0 &&
                  item2.subject_data.length > 1
              )?_c('div',[_c('el-tabs',{staticClass:"subject-menu",attrs:{"type":"border-card","tab-position":_vm.tabPosition},model:{value:(_vm.activeName3),callback:function ($$v) {_vm.activeName3=$$v},expression:"activeName3"}},_vm._l((item2.subject_data),function(item3){return _c('el-tab-pane',{key:item3.subject_id,attrs:{"label":item3.subject_name,"name":item3.subject_id.toString()}},[_c('el-row',{staticClass:"subject-item"},[_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-graduate"}),_vm._v("知识点练习")])]),_c('el-col',{attrs:{"span":6}},[_c('router-link',{attrs:{"to":{
                          path:
                            '/exam/subject?parent_id=' +
                            item.licence_id +
                            '&licence_id=' +
                            item2.licence_id +
                            '&subject_id=' +
                            item3.subject_id +
                            '&licence_name=' +
                            item.licence_name
                        },"tag":"a","target":"_blank"}},[_c('span',{staticClass:"icon-list21"}),_vm._v("顺序练习 ")])],1),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-clipboard3"}),_vm._v("进入自测")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-times-circle-o"}),_vm._v("错题集")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-file-text-o"}),_vm._v("练习记录 ")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-grid"}),_vm._v("题型练习")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-test-paper"}),_vm._v("专项练习")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{on:{"click":function($event){return _vm.toexam(
                            item.licence_id,
                            item2.licence_id,
                            item3.subject_id,
                            item.licence_name
                          )}}},[_c('span',{staticClass:"icon-list-alt"}),_vm._v("模拟考试 ")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-circle-o"}),_vm._v("未做题")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-star-full1"}),_vm._v("收藏夹")])])],1)],1)}),1)],1):_vm._e(),(
                item2.subject_data.length != 0 &&
                  item2.subject_data.length <= 1
              )?_c('div',_vm._l((item2.subject_data),function(item3){return _c('span',{key:item3.subject_id},[_c('el-row',{staticClass:"subject-item"},[_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-graduate"}),_vm._v("知识点练习")])]),_c('el-col',{attrs:{"span":6}},[_c('router-link',{attrs:{"to":{
                        path:
                          '/exam/subject?parent_id=' +
                          item.licence_id +
                          '&licence_id=' +
                          item2.licence_id +
                          '&subject_id=' +
                          item3.subject_id +
                          '&licence_name=' +
                          item.licence_name
                      },"tag":"a","target":"_blank"}},[_c('span',{staticClass:"icon-list21"}),_vm._v("顺序练习 ")])],1),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-clipboard3"}),_vm._v("进入自测")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-times-circle-o"}),_vm._v("错题集")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-file-text-o"}),_vm._v("练习记录")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-grid"}),_vm._v("题型练习")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-test-paper"}),_vm._v("专项练习")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{on:{"click":function($event){return _vm.toexam(
                          item.licence_id,
                          item2.licence_id,
                          item3.subject_id,
                          item.licence_name
                        )}}},[_c('span',{staticClass:"icon-list-alt"}),_vm._v("模拟考试 ")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-circle-o"}),_vm._v("未做题")])]),_c('el-col',{attrs:{"span":6}},[_c('a',{attrs:{"href":"#"}},[_c('span',{staticClass:"icon-star-full1"}),_vm._v("收藏夹")])])],1)],1)}),0):_vm._e(),(item2.subject_data.length == 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}]},[_c('el-tabs',{staticClass:"subject-menu"})],1):_vm._e()])}),1)],1)}),1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/images/banner1.jpg"),"alt":""}})])}]

export { render, staticRenderFns }