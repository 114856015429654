<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/exam' }">考试</el-breadcrumb-item>
        <el-breadcrumb-item>{{ licence_name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="exercise-box">
      <el-tabs
        :tab-position="tabPosition"
        v-model="activeName"
        class="exercise-list"
        @tab-click="handleClick"
      >
        <!-- 最外层 -->
        <el-tab-pane
          :label="item.licence_name"
          :name="item.licence_id.toString()"
          v-for="item in licenceDatas"
          :key="item.licence_id"
        >
          <el-tabs
            type="border-card"
            v-model="activeName2"
            @tab-click="handleClick2"
          >
            <el-tab-pane
              :label="item2.licence_name"
              :name="item2.licence_id.toString()"
              v-for="item2 in item.children"
              :key="item2.licence_id"
            >
              <div
                v-if="
                  item2.subject_data.length != 0 &&
                    item2.subject_data.length > 1
                "
              >
                <el-tabs
                  type="border-card"
                  :tab-position="tabPosition"
                  class="subject-menu"
                  v-model="activeName3"
                >
                  <el-tab-pane
                    :label="item3.subject_name"
                    :name="item3.subject_id.toString()"
                    v-for="item3 in item2.subject_data"
                    :key="item3.subject_id"
                  >
                    <el-row class="subject-item">
                      <el-col :span="6"
                        ><a href="#"
                          ><span class="icon-graduate"></span>知识点练习</a
                        ></el-col
                      >
                      <el-col :span="6">
                        <router-link
                          :to="{
                            path:
                              '/exam/subject?parent_id=' +
                              item.licence_id +
                              '&licence_id=' +
                              item2.licence_id +
                              '&subject_id=' +
                              item3.subject_id +
                              '&licence_name=' +
                              item.licence_name
                          }"
                          tag="a"
                          target="_blank"
                        >
                          <span class="icon-list21"></span>顺序练习
                        </router-link>
                      </el-col>
                      <el-col :span="6"
                        ><a href="#"
                          ><span class="icon-clipboard3"></span>进入自测</a
                        ></el-col
                      >
                      <el-col :span="6"
                        ><a href="#"
                          ><span class="icon-times-circle-o"></span>错题集</a
                        ></el-col
                      >
                      <el-col :span="6">
                        <a href="#">
                          <span class="icon-file-text-o"></span>练习记录
                        </a>
                      </el-col>
                      <el-col :span="6"
                        ><a href="#"
                          ><span class="icon-grid"></span>题型练习</a
                        ></el-col
                      >
                      <el-col :span="6"
                        ><a href="#"
                          ><span class="icon-test-paper"></span>专项练习</a
                        ></el-col
                      >
                      <el-col :span="6">
                        <!-- <router-link
                          :to="{
                            path:
                              '/exam/examitem?parent_id=' +
                              item.licence_id +
                              '&licence_id=' +
                              item2.licence_id +
                              '&subject_id='
                          }"
                          tag="a"
                          target="_blank"
                        > -->
                        <a
                          @click="
                            toexam(
                              item.licence_id,
                              item2.licence_id,
                              item3.subject_id,
                              item.licence_name
                            )
                          "
                        >
                          <span class="icon-list-alt"></span>模拟考试
                        </a>
                        <!-- </router-link> -->
                      </el-col>
                      <el-col :span="6"
                        ><a href="#"
                          ><span class="icon-circle-o"></span>未做题</a
                        ></el-col
                      >
                      <el-col :span="6"
                        ><a href="#"
                          ><span class="icon-star-full1"></span>收藏夹</a
                        ></el-col
                      >
                    </el-row>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div
                v-if="
                  item2.subject_data.length != 0 &&
                    item2.subject_data.length <= 1
                "
              >
                <span
                  v-for="item3 in item2.subject_data"
                  :key="item3.subject_id"
                >
                  <el-row class="subject-item">
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-graduate"></span>知识点练习</a
                      ></el-col
                    >
                    <el-col :span="6">
                      <!--                    <a href="#/exam/subject">-->
                      <router-link
                        :to="{
                          path:
                            '/exam/subject?parent_id=' +
                            item.licence_id +
                            '&licence_id=' +
                            item2.licence_id +
                            '&subject_id=' +
                            item3.subject_id +
                            '&licence_name=' +
                            item.licence_name
                        }"
                        tag="a"
                        target="_blank"
                      >
                        <span class="icon-list21"></span>顺序练习
                      </router-link>
                      <!--                    </a>-->
                    </el-col>
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-clipboard3"></span>进入自测</a
                      ></el-col
                    >
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-times-circle-o"></span>错题集</a
                      ></el-col
                    >
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-file-text-o"></span>练习记录</a
                      ></el-col
                    >
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-grid"></span>题型练习</a
                      ></el-col
                    >
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-test-paper"></span>专项练习</a
                      ></el-col
                    >
                    <el-col :span="6">
                      <!-- <router-link
                        :to="{
                          path:
                            '/exam/examitem?parent_id=' +
                            item.licence_id +
                            '&licence_id=' +
                            item2.licence_id +
                            '&subject_id='
                        }"
                        tag="a"
                        target="_blank"
                      > -->
                      <a
                        @click="
                          toexam(
                            item.licence_id,
                            item2.licence_id,
                            item3.subject_id,
                            item.licence_name
                          )
                        "
                      >
                        <span class="icon-list-alt"></span>模拟考试
                      </a>
                      <!-- </router-link> -->
                    </el-col>
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-circle-o"></span>未做题</a
                      ></el-col
                    >
                    <el-col :span="6"
                      ><a href="#"
                        ><span class="icon-star-full1"></span>收藏夹</a
                      ></el-col
                    >
                  </el-row>
                </span>
              </div>
              <div v-if="item2.subject_data.length == 0" v-loading="true">
                <el-tabs class="subject-menu"></el-tabs>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <!--        <el-tab-pane label="驾驶证">-->
        <!--          <el-tabs type="border-card">-->
        <!--            <el-tab-pane label="小车">-->
        <!--              <el-tabs-->
        <!--                type="border-card"-->
        <!--                :tab-position="tabPosition"-->
        <!--                class="subject-menu"-->
        <!--              >-->
        <!--                <el-tab-pane label="科目一">-->
        <!--                  <el-row class="subject-item">-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="/exam/subject"-->
        <!--                        ><span class="icon-list21"></span>顺序练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-grid"></span>题型练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-circle-o"></span>未做题</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                  </el-row>-->
        <!--                </el-tab-pane>-->
        <!--                <el-tab-pane label="科目二">-->
        <!--                  <el-row class="subject-item">-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-graduate"></span>a知识点练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list21"></span>a顺序练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-grid"></span>题型练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-circle-o"></span>未做题</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                  </el-row>-->
        <!--                </el-tab-pane>-->
        <!--              </el-tabs>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="客车">-->
        <!--              <el-tabs-->
        <!--                type="border-card"-->
        <!--                :tab-position="tabPosition"-->
        <!--                class="subject-menu"-->
        <!--              >-->
        <!--                <el-tab-pane label="科目一">-->
        <!--                  <el-row class="subject-item">-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list21"></span>顺序练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-grid"></span>题型练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-circle-o"></span>未做题</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                  </el-row>-->
        <!--                </el-tab-pane>-->
        <!--                <el-tab-pane label="科目二">-->
        <!--                  <el-row class="subject-item">-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-graduate"></span>a知识点练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list21"></span>a顺序练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-grid"></span>题型练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-circle-o"></span>未做题</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                  </el-row>-->
        <!--                </el-tab-pane>-->
        <!--              </el-tabs>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="货车">-->
        <!--              <el-tabs-->
        <!--                type="border-card"-->
        <!--                :tab-position="tabPosition"-->
        <!--                class="subject-menu"-->
        <!--              >-->
        <!--                <el-tab-pane label="科目一">-->
        <!--                  <el-row class="subject-item">-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list21"></span>顺序练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-grid"></span>题型练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-circle-o"></span>未做题</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                  </el-row>-->
        <!--                </el-tab-pane>-->
        <!--                <el-tab-pane label="科目二">-->
        <!--                  <el-row class="subject-item">-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-graduate"></span>a知识点练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list21"></span>a顺序练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-grid"></span>题型练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-circle-o"></span>未做题</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                    <el-col :span="6"-->
        <!--                      ><a href="#"-->
        <!--                        ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                      ></el-col-->
        <!--                    >-->
        <!--                  </el-row>-->
        <!--                </el-tab-pane>-->
        <!--              </el-tabs>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="摩托车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="货运从业知识">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs>-->
        <!--        </el-tab-pane>-->
        <!--        <el-tab-pane label="资格证">-->
        <!--          <el-tabs type="border-card">-->
        <!--            <el-tab-pane label="客车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="货车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="客货运">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="出租车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="网约车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="摩托车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs>-->
        <!--        </el-tab-pane>-->
        <!--        <el-tab-pane label="继续教育">-->
        <!--          <el-tabs type="border-card">-->
        <!--            <el-tab-pane label="网约车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="客运">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="货运">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="教练员">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="出租车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="危货">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="安全员">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="公交车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs>-->
        <!--        </el-tab-pane>-->
        <!--        <el-tab-pane label="满分与审验教育">-->
        <!--          <el-tabs type="border-card">-->
        <!--            <el-tab-pane label="满分教育">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="审验教育">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs>-->
        <!--        </el-tab-pane>-->
        <!--        <el-tab-pane label="安全教育">-->
        <!--          <el-tabs type="border-card">-->
        <!--            <el-tab-pane label="客运">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="货运">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="危货">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="公交车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="责任人及安全员">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="出租车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="自动驾驶安全员">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs>-->
        <!--        </el-tab-pane>-->
        <!--        <el-tab-pane label="交通安全专项行动">-->
        <!--          <el-tabs type="border-card">-->
        <!--            <el-tab-pane label="客运">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="货运">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="危货">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--            <el-tab-pane label="公交车">-->
        <!--              <el-row>-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-graduate"></span>知识点练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list21"></span>顺序练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-clipboard3"></span>进入自测</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-times-circle-o"></span>错题集</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-file-text-o"></span>练习记录</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-grid"></span>题型练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-test-paper"></span>专项练习</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-list-alt"></span>模拟考试</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-circle-o"></span>未做题</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--                <el-col :span="6"-->
        <!--                  ><a href="#"-->
        <!--                    ><span class="icon-star-full1"></span>收藏夹</a-->
        <!--                  ></el-col-->
        <!--                >-->
        <!--              </el-row>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs>-->
        <!--        </el-tab-pane>-->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getlicence } from '../api/licence'
import { getSubject } from '../api/courses'
import { getResult } from '../utils/auth'

export default {
  name: 'Home',
  data() {
    return {
      tabPosition: 'left',
      licenceDatas: [],
      activeName: '',
      activeName2: '',
      activeName3: '',
      licence_id: 0,
      parent_id: 0,
      licence_name: ''
    }
  },
  components: {},
  created() {
    this.parent_id = this.$route.query.parent_id
    this.licence_id = this.$route.query.licence_id
    this.getlicenceData(this.parent_id, this.licence_id)
  },
  methods: {
    getlicenceData(parent_id, licence_id) {
      getlicence().then(res => {
        if (res.code === 0) {
          let licenceData = res.result
          for (let i = 0; i < licenceData.length; i++) {
            if (parent_id == licenceData[i].licence_id) {
              this.licence_name = licenceData[i].licence_name
              this.activeName = parent_id.toString()
            }
            if (licenceData[i].hasOwnProperty('children') === true) {
              for (let s = 0; s < licenceData[i].children.length; s++) {
                if (licence_id == licenceData[i].children[s].licence_id) {
                  this.activeName2 = licence_id.toString()
                }
                licenceData[i].children[s].subject_data = []
                getSubject({
                  licence_id: licenceData[i].children[s].licence_id
                }).then(res => {
                  if (res.code === 0) {
                    licenceData[i].children[s].subject_data =
                      res.result.subject_list
                    if (
                      this.activeName2 == licenceData[i].children[s].licence_id
                    ) {
                      this.activeName3 = licenceData[i].children[
                        s
                      ].subject_data[0].subject_id.toString()
                    }
                  }
                })
              }
            }
          }
          this.licenceDatas = licenceData
          return
        }
      })
    },
    handleClick(tab, event) {
      this.licence_name = tab.label
      for (let i = 0; i < this.licenceDatas.length; i++) {
        if (tab.name == this.licenceDatas[i].licence_id) {
          this.activeName2 = this.licenceDatas[
            i
          ].children[0].licence_id.toString()
        }
        if (this.licenceDatas[i].hasOwnProperty('children') === true) {
          for (let s = 0; s < this.licenceDatas[i].children.length; s++) {
            if (
              this.activeName2 == this.licenceDatas[i].children[s].licence_id
            ) {
              this.activeName3 = this.licenceDatas[i].children[
                s
              ].subject_data[0].subject_id.toString()
            }
          }
        }
      }
    },
    handleClick2(tab, event) {
      for (let i = 0; i < this.licenceDatas.length; i++) {
        if (this.licenceDatas[i].hasOwnProperty('children') === true) {
          for (let s = 0; s < this.licenceDatas[i].children.length; s++) {
            if (tab.name == this.licenceDatas[i].children[s].licence_id) {
              this.activeName3 = this.licenceDatas[i].children[
                s
              ].subject_data[0].subject_id.toString()
            }
          }
        }
      }
    },
    toexam(parent_id, licence_id, subject_id, licence_name) {
      if (getResult() == undefined) {
        this.$router.push({
          path: '/login'
        })
      } else {
        this.$router.push({
          path:
            '/exam/examitem?parent_id=' +
            parent_id +
            '&licence_id=' +
            licence_id +
            '&subject_id=' +
            subject_id +
            '&licence_name=' +
            licence_name
        })
      }
    }
  }
}
</script>
