import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function getlicence(data) {
  return request({
    url: VERSION + '/course/getLicenceList',
    method: 'get',
    params: data
  })
}
